import React, { useEffect, useMemo, useState } from "react";
import { Borough, useOnboardingContext } from "./onboardingContext";
import { SelectChangeEvent } from "@mui/material";
import { LocationCoords } from "/@/pages/index/+Page";
import dayjs from "dayjs";
import {
  AllAveragedAirQualityData,
  ChosenGraphSensor,
  ChosenGuideline,
  ChosenPeriod,
  Interval,
  MapCenterCoords,
  MapMetric,
  Metric,
  Pollutant,
} from "/@/types/mapTypes";
import {
  BOROUGH_LOCATIONS,
  POLLUTANT_OPTIONS,
} from "/@/constants/mapConstants";
import {
  decideInterval,
  getDaysBetweenDates,
  hasPollutantData,
} from "/@/utils/mapUtils";
import { PollutantType } from "/@/constants/chartContants";

interface MapContextType {
  getMapCenter: () => MapCenterCoords;
  handleBoroughChange: (boroughName: Borough) => void;
  handlePollutantChange: (event: SelectChangeEvent<unknown>) => void;
  chosenBorough: Borough;
  chosenPollutant: Pollutant;
  chosenGuideline: ChosenGuideline;
  locationCoords: LocationCoords | undefined;
  setLocationCoords: React.Dispatch<
    React.SetStateAction<LocationCoords | undefined>
  >;
  setChosenGraphSensor: React.Dispatch<
    React.SetStateAction<ChosenGraphSensor | null>
  >;
  chosenGraphSensor: ChosenGraphSensor | null;
  getBoroughLocation: (borough: Borough) => {
    lat: number;
    lng: number;
  };
  setMapRef: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>;
  mapRef: google.maps.Map | undefined;
  disabledPollutants: Pollutant[];
  chosenDateRange: ChosenPeriod;
  setChosenDateRange: React.Dispatch<React.SetStateAction<ChosenPeriod>>;
  airQualityData: AllAveragedAirQualityData;
  setAirQualityData: React.Dispatch<
    React.SetStateAction<AllAveragedAirQualityData>
  >;
  selectedIFrameMap: string | null;
  setSelectedIFrameMap: React.Dispatch<React.SetStateAction<string | null>>;
  setChosenGuideline: React.Dispatch<React.SetStateAction<ChosenGuideline>>;
  chosenGraphInterval: Interval;
  setChosenGraphInterval: (interval: Interval) => void;
  chosenGraphMetric: Metric;
  setChosenGraphMetric: (metric: Metric) => void;
  chosenRadius: number;
  chosenMapPinInterval: Interval;
  setChosenMapPinInterval: React.Dispatch<React.SetStateAction<Interval>>;
  chosenMapPinDateRange: ChosenPeriod;
  setChosenMapPinDateRange: React.Dispatch<React.SetStateAction<ChosenPeriod>>;
  handleGraphDateRangeChange: (dateRange: ChosenPeriod) => void;
  chosenMapPinMetric: MapMetric;
  handleMapDateRangeChange: (
    newMapReadingType: string,
    newChosenDateRange: ChosenPeriod,
    newChosenMapPinMetric: MapMetric,
    newChosenMapPinInterval: Interval
  ) => void;
  mapReadingType: string;
  setMapReadingType: React.Dispatch<React.SetStateAction<string>>;
  selectedPollutant: PollutantType;
  setSelectedPollutant: React.Dispatch<React.SetStateAction<PollutantType>>;
  selectedMobilePollutant: PollutantType;
  setSelectedMobilePollutant: React.Dispatch<
    React.SetStateAction<PollutantType>
  >;
  setChosenBorough: React.Dispatch<React.SetStateAction<Borough>>;
}

const MapContext = React.createContext<MapContextType>({
  getMapCenter: () => ({ lat: 51.5072, lng: -0.1276 }),
  handleBoroughChange: () => {},
  handlePollutantChange: () => {},
  chosenBorough: "Hackney",
  chosenPollutant: "pm10",
  locationCoords: undefined,
  setLocationCoords: () => {},
  setChosenGraphSensor: () => {},
  chosenGraphSensor: null,
  getBoroughLocation: () => ({ lat: 51.5436, lng: -0.0554 }),
  setMapRef: () => {},
  mapRef: undefined,
  disabledPollutants: [],
  chosenDateRange: [dayjs().subtract(7, "day"), dayjs()],
  setChosenDateRange: () => {},
  airQualityData: { pm10: [], pm25: [], no2: [] },
  setAirQualityData: () => {},
  selectedIFrameMap: null,
  chosenGuideline: "DEFRA",
  setSelectedIFrameMap: () => {},
  setChosenGuideline: () => {},
  chosenGraphInterval: "hour",
  setChosenGraphInterval: () => {},
  chosenGraphMetric: "avg",
  setChosenGraphMetric: () => {},
  chosenRadius: 10,
  chosenMapPinInterval: "hour",
  setChosenMapPinInterval: () => {},
  chosenMapPinDateRange: [dayjs().subtract(30, "day"), dayjs()],
  setChosenMapPinDateRange: () => {},
  handleGraphDateRangeChange: () => {},
  chosenMapPinMetric: "latest",
  handleMapDateRangeChange: () => {},
  setMapReadingType: () => {},
  mapReadingType: "todays-latest-reading",
  selectedPollutant: "PM2.5",
  setSelectedPollutant: () => {},
  selectedMobilePollutant: "PM2.5",
  setSelectedMobilePollutant: () => {},
  setChosenBorough: () => {},
});

export const useMapContext = () => React.useContext(MapContext);

interface Props {
  children: React.ReactNode;
}

export const MapProvider = ({ children }: Props) => {
  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const [locationCoords, setLocationCoords] = useState<LocationCoords>();
  const [disabledPollutants, setDisabledPollutants] = useState<Pollutant[]>([]);
  const { boroughs } = useOnboardingContext();
  const [chosenBorough, setChosenBorough] = useState<Borough>(boroughs[0]);
  const [chosenPollutant, setChosenPollutant] = useState<Pollutant>(
    POLLUTANT_OPTIONS[0]
  );
  const [selectedMobilePollutant, setSelectedMobilePollutant] =
    useState<PollutantType>("PM2.5");

  const [chosenDateRange, setChosenDateRange] = useState<ChosenPeriod>([
    dayjs().subtract(7, "day"),
    dayjs(),
  ]);
  const [airQualityData, setAirQualityData] =
    useState<AllAveragedAirQualityData>({ pm10: [], pm25: [], no2: [] });
  const [selectedIFrameMap, setSelectedIFrameMap] = useState<string | null>(
    null
  );
  const [chosenGuideline, setChosenGuideline] =
    useState<ChosenGuideline>("DEFRA");
  const [chosenGraphInterval, setChosenGraphInterval] =
    useState<Interval>("day");
  const [chosenRadius, setChosenRadius] = useState<number>(10);
  const [chosenGraphMetric, setChosenGraphMetric] = useState<Metric>("avg");
  const [chosenMapPinInterval, setChosenMapPinInterval] =
    useState<Interval>("hour");
  const [chosenMapPinDateRange, setChosenMapPinDateRange] =
    useState<ChosenPeriod>([dayjs().subtract(90, "day"), dayjs()]);
  const [chosenGraphSensor, setChosenGraphSensor] =
    useState<ChosenGraphSensor | null>(null);
  const [chosenMapPinMetric, setChosenMapPinMetric] =
    useState<MapMetric>("latest");
  const [mapReadingType, setMapReadingType] = useState<string>(
    "todays-latest-reading"
  );
  const [selectedPollutant, setSelectedPollutant] =
    useState<PollutantType>("PM2.5");

  useEffect(() => {
    if (airQualityData) {
      const availablePollutants = (
        ["PM2.5", "PM10", "NO2"] as PollutantType[]
      ).filter((pollutant) => hasPollutantData(airQualityData, pollutant));

      // If current selection has no data but there are other options available
      if (
        availablePollutants.length > 0 &&
        !hasPollutantData(airQualityData, selectedPollutant)
      ) {
        setSelectedPollutant(availablePollutants[0]);
      }
    }
  }, [airQualityData]);

  const handleMapDateRangeChange = (
    newMapReadingType: string,
    newChosenDateRange: ChosenPeriod,
    newChosenMapPinMetric: MapMetric,
    newChosenMapPinInterval: Interval
  ) => {
    setMapReadingType(newMapReadingType);
    setChosenMapPinDateRange(newChosenDateRange);
    setChosenMapPinMetric(newChosenMapPinMetric);
    setChosenMapPinInterval(newChosenMapPinInterval);
  };

  const handleGraphDateRangeChange = (newChosenDateRange: ChosenPeriod) => {
    const startDate = newChosenDateRange![0]!.toISOString();
    const endDate = newChosenDateRange![1]!.toISOString();
    const daysInBetween = getDaysBetweenDates(startDate, endDate);
    const defaultInterval = decideInterval(daysInBetween);
    setChosenGraphInterval(defaultInterval as Interval);
    setChosenDateRange(newChosenDateRange);
  };

  const getMapCenter = (): MapCenterCoords => {
    if (localStorage.getItem("userMetadata")) {
      const council = JSON.parse(localStorage.getItem("userMetadata") as string)
        .council as Borough;
      return getBoroughLocation(council);
    } else {
      return { lat: 51.525976, lng: -0.025496 };
    }
  };

  const getBoroughLocation = (borough: Borough) => {
    return BOROUGH_LOCATIONS[borough] || BOROUGH_LOCATIONS["Hackney"];
  };

  useEffect(() => {
    if (chosenGraphSensor) {
      if (disabledPollutants.includes(chosenPollutant)) {
        const newInitialPollutant = POLLUTANT_OPTIONS.filter(
          (option) => !disabledPollutants.includes(option)
        )[0];
        setChosenPollutant(newInitialPollutant);
      }
    }
  }, [disabledPollutants]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/boroughs/")) {
      const boroughSlug = path.split("/boroughs/")[1];
      // Map from URL slug to borough name
      const boroughMap: Record<string, Borough> = {
        hackney: "Hackney",
        newham: "Newham",
        "city-of-london": "City of London",
        "tower-hamlets": "Tower Hamlets",
      };
      if (boroughSlug in boroughMap) {
        setChosenBorough(boroughMap[boroughSlug]);
      }
    }
  }, []);

  const handleBoroughChange = (boroughName: Borough) => {
    setChosenGraphSensor(null);
    setDisabledPollutants([]);
    setChosenBorough(boroughName);
  };

  const handlePollutantChange = (event: SelectChangeEvent<unknown>) => {
    setChosenPollutant(event.target.value as Pollutant);
  };

  const contextValue = useMemo(() => {
    return {
      setMapRef,
      mapRef,
      getMapCenter: () => getMapCenter(),
      handleBoroughChange,
      handlePollutantChange,
      chosenBorough,
      chosenPollutant,
      locationCoords,
      setLocationCoords,
      setChosenGraphSensor,
      chosenGraphSensor,
      getBoroughLocation: () => getBoroughLocation(chosenBorough),
      disabledPollutants,
      chosenDateRange,
      setChosenDateRange,
      airQualityData,
      setAirQualityData,
      selectedIFrameMap,
      setSelectedIFrameMap,
      setChosenGuideline,
      chosenGuideline,
      chosenGraphInterval,
      setChosenGraphInterval,
      chosenRadius,
      setChosenRadius,
      chosenGraphMetric,
      setChosenGraphMetric,
      chosenMapPinInterval,
      setChosenMapPinInterval,
      chosenMapPinDateRange,
      setChosenMapPinDateRange,
      handleGraphDateRangeChange,
      chosenMapPinMetric,
      handleMapDateRangeChange,
      setMapReadingType,
      mapReadingType,
      selectedPollutant,
      setSelectedPollutant,
      selectedMobilePollutant,
      setSelectedMobilePollutant,
      setChosenBorough,
    };
  }, [
    mapRef,
    chosenBorough,
    chosenPollutant,
    locationCoords,
    chosenGraphSensor,
    disabledPollutants,
    chosenDateRange,
    airQualityData,
    setChosenDateRange,
    selectedIFrameMap,
    setSelectedIFrameMap,
    setChosenGuideline,
    chosenGuideline,
    chosenGraphInterval,
    setChosenGraphInterval,
    chosenRadius,
    setChosenRadius,
    chosenGraphMetric,
    setChosenGraphMetric,
    chosenMapPinDateRange,
    setChosenMapPinDateRange,
    handleGraphDateRangeChange,
    chosenMapPinMetric,
    handleMapDateRangeChange,
    mapReadingType,
    setMapReadingType,
    selectedPollutant,
    setChosenBorough,
  ]);

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
};
